/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.5.0/dist/css/bootstrap-reboot.min.css
 * - /npm/bootstrap@4.5.0/dist/css/bootstrap-grid.min.css
 * - /npm/toastr@2.1.4/build/toastr.min.css
 * - /npm/photoswipe@5.2.8/dist/photoswipe.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
